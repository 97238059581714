import './product-range-teaser.scss';
import inView from 'in-view';
import Swiper from 'swiper';
import { A11y, Navigation, Pagination, Keyboard } from 'swiper/modules';

class ProductRangeTeaser {
    constructor (el, options) {
        const settings = {
            IS_DISABLED: 'is--disabled',
            GROUP_ATTR: 'data-product-range-teaser-group',
            GROUP_BACKGROUND_ATTR: 'data-product-range-teaser-group-background',
            BRIGHT_ATTR: 'data-product-range-teaser-group-bright',
            BRIGHT_CLASS: 'is--bright',
            BUTTON_PRIMARY_CLASS: 'button--secondary',
            BUTTON_SECONDARY_CLASS: 'button--secondary'
        };
        this.$productRangeTeaser = el;
        const $el = this.$productRangeTeaser;

        this.$products = $el.querySelectorAll(`[${settings.GROUP_ATTR}]`);
        this.$content = $el.querySelectorAll('.product-range-teaser__content')[0];
        this.$background = $el.querySelectorAll('.product-range-teaser__background')[0];
        this.backgroundImage = '';
        this.$productsWrapper = this.$products[0].parentNode;
        this.$button = $el.querySelectorAll('.button')[0];
        this.$modalClose = document.querySelector('.modal__close');

        this.settings = Object.assign({}, settings, options);

        // this.$showProductNameButton = $el.querySelector('[data-product-range-teaser-product-name]');

        this.$slider = this.$productRangeTeaser.querySelector('[data-product-range-teaser-slider="root"]');
        this.$slides = this.$productRangeTeaser.querySelectorAll('[data-product-range-teaser-slider="slide"]');
        this.slider = null;
    }

    initialize () {
        this.registerEvents();
        this.replaceUrl();
        this.initSlider();
    }

    initSlider () {
        if (this.$slider && this.$slides.length > 0) {
            this.slider = new Swiper(this.$slider, {
                modules: [A11y, Navigation, Pagination, Keyboard],
                a11y: true,
                lazyPreloadPrevNext: true,
                keyboard: true,
                slidesPerView: 'auto',
                speed: 250,
                allowTouchMove: true,
                slideClass: 'product-range-teaser__slide',
                wrapperClass: 'product-range-teaser__slides',
                spaceBetween: 28,
                pagination: {
                    el: '.product-range-teaser__slider-pagination',
                    type: 'fraction'
                },
                navigation: {
                    nextEl: '.product-range-teaser__slider-navigation-next',
                    prevEl: '.product-range-teaser__slider-navigation-prev'
                },
                breakpoints: {
                    1024: {
                        spaceBetween: 20,
                        allowTouchMove: true
                    }
                }
            });
        }
    }

    registerEvents () {
        this.$products.forEach((product) => {
            product.addEventListener('mouseenter', (e) => {
                // const $target = e.target;
                // this.disableSiblingProducts(this.getSiblings($target));

                // this.setCurrentProductColor($target);
                // this.$showProductNameButton.innerHTML = $target.getAttribute('data-product-range-teaser-product');
                // this.$showProductNameButton.style.display = 'block';
            }, false);

            product.addEventListener('mouseleave', () => {
                // this.enableProducts();
                // this.removeProductColor();

                // this.$showProductNameButton.style.display = 'none';
            }, false);

            product.addEventListener('click', (e) => {
                const $target = e.target;
                const sliderValue = $target.closest('[data-product-range-teaser-product-id]').getAttribute('data-product-range-teaser-product-id');
                if (window.location.href.indexOf('#') > -1) {
                    window.location.href = window.location.href.replaceAll('[0-9]', sliderValue);
                } else {
                    window.location.href = window.location.href + '#' + sliderValue;
                }
            }, false);
        });

        let scrolling;
        this.$productsWrapper.addEventListener('scroll', (e) => {
            const $el = e.target;
            if ($el.scrollLeft) {
                window.clearTimeout(scrolling);
                $el.classList.add('is--moving');

                scrolling = setTimeout(() => {
                    $el.classList.remove('is--moving');
                }, 150);
            }
        }, false);

        inView('[data-product-range-teaser="root"]').once('enter', () => this.animateOnEnter());
    }

    // remove url parameter on close
    replaceUrl () {
        this.$modalClose.addEventListener('click', () => {
            if (window.location.href.includes('#')) {
                window.history.pushState({}, null, window.location.href.split('#')[0]);
            }
        });
    }

    animateOnEnter () {
        this.$productRangeTeaser.classList.add('is--animated');
    }

    enableProducts () {
        this.$products.forEach(product => {
            product.classList.remove(this.settings.IS_DISABLED);
        });
    }

    disableSiblingProducts ($siblings) {
        $siblings.map(sibling => { // eslint-disable-line
            sibling.classList.add(this.settings.IS_DISABLED);
        });
    }

    setCurrentProductColor ($target) {
        if ($target.getAttribute(this.settings.BRIGHT_ATTR) === '1') {
            this.$content.classList.add(this.settings.BRIGHT_CLASS);
        }
    }

    removeProductColor () {
        this.$content.classList.remove(this.settings.BRIGHT_CLASS);
    }

    getSiblings (elem) {
        return [].filter.call(elem.parentNode.children, (sibling) => {
            return sibling.getAttribute(this.settings.GROUP_ATTR) !== elem.getAttribute(this.settings.GROUP_ATTR);
        });
    }
}

export { ProductRangeTeaser };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $$roots = $context.querySelectorAll('[data-product-range-teaser="root"]');
        if ($$roots.length > 0) {
            $$roots.forEach($root => {
                const $rootAPI = new ProductRangeTeaser($root);
                $rootAPI.initialize();
            });
        }
    }
});
